<template>
	<div v-show="show"
		style="background-color: rgba(0,0,0,0.3);width: 100vw;height:100vh;z-index: 9999;position: fixed;top: 0;left: 0;">
		<el-dialog title="填写情况" :visible.sync="show" width="55%">
			<el-tabs v-model="activeName">
			    <el-tab-pane label="详情信息" name="first">
					<zhanbi ref="zhanbi" :tj_data="tj_data"></zhanbi>
				</el-tab-pane>
			    <el-tab-pane label="填写人员" name="second">
					<div style="width: 100%;display: flex;align-items: center;justify-content: space-between;">
						<div style="width: 100%;height: 60vh;overflow-y:auto;padding: 20px;font-size: 16px;">
							<div style="margin-bottom: 10px;">未填写人数<span style="color:#277bf5">({{weitainxie.length}}人)</span>
							</div>
					
							<div style="width:100%;">
								<div style="min-height: 49px;display: flex;align-items: center;justify-content: space-between;width: 100%;padding: 10px 0;border-bottom: 1px solid #eee;"
									v-for="item in weitainxie">
									<div style="color: #333;font-size:16px;font-weight: bold;">
										{{item.user_name?item.user_name:''}}
									</div>
									<!-- <div style="font-size: 14px;">
										<span style="margin-right: 10px;">签到时间：</span><span
											style="color:#333;">{{item.qdtime}}</span>
									</div> -->
								</div>
							</div>
						</div>
						<div style="width: 100%;height: 60vh;overflow-y:auto;padding: 20px;font-size: 16px;">
							<div style="margin-bottom: 10px;">已填写人数<span style="color:#277bf5">({{yitianxie.length}}人)</span>
							</div>
					
							<div style="width:100%;">
								<div style="display: flex;align-items: center;justify-content: space-between;width: 100%;padding: 10px 0;border-bottom: 1px solid #eee;"
									v-for="item in yitianxie">
									<div style="color: #333;font-size:16px;font-weight: bold;">
										{{item.user_name?item.user_name:''}}
									</div>
									<div style="font-size: 14px;">
										<el-button type="success" size="mini" @click="showbm(item)">查看答卷</el-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
			
			

			<span slot="footer" class="dialog-footer">
				<el-button @click="show = false">取 消</el-button>
				<el-button type="primary" @click="show = false">确 定</el-button>
			</span>
		</el-dialog>
		
		<mydetail ref="mydetail"></mydetail>
		
		
	</div>
</template>

<script>
	import mydetail from './detail.vue';
	import zhanbi from './zhanbi.vue';
	export default {
		components:{
			mydetail,
			zhanbi
		},
		data() {
			return {
				activeName: 'first',
				show: false,
				id: '',
				weitainxie: [],
				yitianxie:[],
				wqdry:'',
				showtcbm:false,
				time:'',
				tj_data:[]
			}
		},
		methods: {
			surebm(){
				this.$post({
					url: '/api/Wymeetingtz/glyhyqd',
					params: {
						id: this.wqdry.id
					}
				}).then((res) => {
					this.showtcbm = false
					this.$message.success('签到成功')
					this.getlist()
				})
			},
			showbm(item){
				this.$post({
					url: '/api/questionnaire/indexta',
					params: {
						questionnaire_id: this.id,
						user_id:item.user_id
					}
				}).then((res) => {
					res.list.forEach((item)=>{
						if(item.type==3){
							item.da = item.da.split(',')
						}
					})
					
					item.timu_list = res.list
					
					Object.keys(item).forEach(key => {
					    this.$set(this.$refs.mydetail.detail, key, item[key]);
					});
					this.$refs.mydetail.dialogVisible = true
				})	
			},
			getlist() {
				this.$post({
					url: '/api/questionnaire/indexry',
					params: {
						questionnaire_id: this.id
					}
				}).then((res) => {
					this.weitainxie = res.list.filter((item)=>{return item.txtype==1})
					this.yitianxie = res.list.filter((item)=>{return item.txtype==2})
					// this.wqiandaoList = res.qbry.filter(o1 => !this.qiandaoList.some(o2 => o1.user_id === o2.user_id));
					// this.$parent.getinfo()
				})
				
				this.$post({
					url: '/api/questionnaire/tj',
					params: {
						questionnaire_id: this.id
					}
				}).then((res) => {
					this.tj_data = res.list
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
		border-bottom: 1px solid #ccc;
		display: none !important;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #ccc;
	}

	::v-deep .el-dialog__body {
		padding-top: 10px;
	}

</style>