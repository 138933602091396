<template>
	<div v-if="dialogVisible">
		<el-dialog :title="detail.name" :visible.sync="dialogVisible" width="85%" :before-close="resetdata"
			:close-on-click-modal="false">
			<div style="width: 100%;height: 85vh;overflow-y: auto;">
				<div class="myform">
					<img src="../../../../assets/dcb_biaot.png" alt="" style="width: 100%;" />
					<div class="formcont">
						<div
							style="width: 100%;font-size: 16px;font-weight: bold;color: #333;text-align: center;margin-bottom: 15px;">
							{{detail.name}}
						</div>
						<div style="width: 100%;font-size: 12px;color: #999;margin-bottom: 15px;">
							{{detail.kstime}} 至 {{detail.jstime}}
						</div>
						
						<div style="text-indent: 2em;margin-bottom: 15px;">
							{{detail.sm}}
						</div>

						<div class="dcbitem" v-if="detail.ry_name">
							<span class="namedcb">调查对象：</span>
							<span class="dcbvalue">{{detail.ry_name}}</span>
						</div>

						<div class="formdiv" v-if="detail.timu_list.length>0">
							<div v-for="(item, index) in detail.timu_list" :key="item.id" class="form-item">
								<span
									style="width: 100%;margin-bottom: 5px;">{{index+1}}、{{ item.name }}{{item.type==3&&item.max?`(最多${item.max}项)`:''}}</span>
								<div style="width: 100%;">
									<el-input v-if="item.type === 1" v-model="item.da" placeholder="请输入"
										:disabled="islook" />
									<el-radio-group v-if="item.type === 2" v-model="item.da" :disabled="islook">
										<el-radio v-for="(option, idx) in item.xx" :key="idx" :label="option.value">
											{{ option.value }}
											<div v-show="item.da==option.value">
												<el-input v-if="option.hasInput" v-model="option.input_value" size="mini" :disabled="islook"
													placeholder="请输入" />
											</div>											
										</el-radio>
									</el-radio-group>
									<el-checkbox-group v-if="item.type === 3" v-model="item.da" :disabled="islook" :max="item.max?item.max:9999">
										<el-checkbox v-for="(option, idx) in item.xx" :key="idx" :label="option.value">
											{{ option.value }}
											<div v-show="item.da.includes(option.value)">
												<el-input v-if="option.hasInput" v-model="option.input_value" size="mini" :disabled="islook"
													placeholder="请输入" />
											</div>											
										</el-checkbox>
									</el-checkbox-group>
									<el-input v-if="item.type === 4" v-model="item.da" type="textarea" :rows="4"
										:disabled="islook" placeholder="请输入" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style="width: 100%;display: flex;align-items: center;justify-content: flex-end;">
				<el-button size='small' style="font-size:14px;" type="" @click="resetdata">关闭</el-button>
				<el-button size='small' style="font-size:14px;" type="primary" v-if="!islook"
					@click="submit">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				islook: true, //是否为只看模式
				dialogVisible: false,
				detail: {
					name: '问卷调查表',
					timu_list: []
				},
				questionnaire_id: ''
			}
		},
		mounted() {},
		methods: {
			resetdata() {
				this.islook = true
				this.dialogVisible = false
			},
			submit() {
				for (let i = 0; i < this.detail.timu_list.length; i++) {
					let item = this.detail.timu_list[i];
					if (item.type === 1 || item.type === 4) {
						if (!item.da) {
							this.$message.warning(`请填写“${item.name}”`);
							return;
						}
					} else if (item.type === 2) {
						if (!item.da) {
							this.$message.warning(`请选择“${item.name}”`);
							return;
						}
					} else if (item.type === 3) {
						if (item.da.length === 0) {
							this.$message.warning(`请选择“${item.name}”`);
							return;
						}
					}
				}
				console.log(this.detail.timu_list);

				let datalist = JSON.parse(JSON.stringify(this.detail.timu_list))
				datalist.forEach((item) => {
					if (item.type == 3) {
						item.da = item.da.join(',')
					}
				})
				// 提交逻辑
				this.$post({
					url: '/api/questionnaire/adddt',
					params: {
						questionnaire_id: this.questionnaire_id,
						list: datalist
					}
				}).then((res) => {
					this.$message.success('提交成功')
					this.resetdata()
					this.$parent.gettable()
				})
			}
		}
	}
</script>

<script>
	export default {
		data() {
			return {
				islook: true, //是否为只看模式
				dialogVisible: false,
				detail: {
					name: '问卷调查表',
					timu_list: []
				},
				questionnaire_id: ''
			}
		},
		mounted() {},
		methods: {
			resetdata() {
				this.islook = true
				this.dialogVisible = false
			},
			submit() {
				for (let i = 0; i < this.detail.timu_list.length; i++) {
					let item = this.detail.timu_list[i];
					if (item.type === 1 || item.type === 4) {
						if (!item.da) {
							this.$message.warning(`请填写“${item.name}”`);
							return;
						}
					} else if (item.type === 2) {
						if (!item.da) {
							this.$message.warning(`请选择“${item.name}”`);
							return;
						}
					} else if (item.type === 3) {
						if (item.da.length === 0) {
							this.$message.warning(`请选择“${item.name}”`);
							return;
						}
					}
				}
				console.log(this.detail.timu_list);

				let datalist = JSON.parse(JSON.stringify(this.detail.timu_list))
				datalist.forEach((item) => {
					if (item.type == 3) {
						item.da = item.da.join(',')
					}
				})
				// 提交逻辑
				this.$post({
					url: '/api/questionnaire/adddt',
					params: {
						questionnaire_id: this.questionnaire_id,
						list: datalist
					}
				}).then((res) => {
					this.$message.success('提交成功')
					this.resetdata()
					this.$parent.gettable()
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.myform {
		width: 45%;
		margin: auto;

		.formcont {
			width: 100%;
			background-color: #fff;
			padding: 10px 20px;
			height: 76vh;
			overflow-y: auto;
		}
	}

	.dcbvalue {
		color: #333;
		width: calc(100% - 120px);
	}

	.namedcb {
		width: 80px;
		display: block;
	}

	.beseinfobox {
		width: 100%;
	}

	.dcbitem {
		display: flex;
		align-items: center;
		margin-bottom: 15px;
		width: 100%;
	}

	.dcbitem:nth-last-child(1) {
		margin-bottom: 0;
	}

	.form-item {
		display: flex;
		flex-direction: column;
		margin-bottom: 25px;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #ccc;
		display: none !important;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #ccc;
	}

	::v-deep .el-dialog__body {
		padding-top: 10px;
	}

	::v-deep .el-dialog {
		margin: 0 !important;
		background-image: url('../../../../assets/dcbg.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	::v-deep .el-radio-group {
		display: flex;
		flex-direction: column;

		.el-radio {
			margin-bottom: 10px;
		}
	}

	::v-deep .el-checkbox-group {
		display: flex;
		flex-direction: column;

		.el-checkbox {
			margin-bottom: 10px;
			display: flex;
			align-items: flex-start;
		}
		.el-checkbox__input{
			margin-top: 3px;
		}
		.el-checkbox__label{
			    width: 100%;
			    display: block;
			    white-space: break-spaces;
		}
	}
</style>